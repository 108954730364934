<template>
  <div class="advertismentWrapper offerManagement listingManagementWrapper">
     <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <b-row>
      <b-col md="12" v-if="!showSpinner">
        <b-card class="tableCardBody">
          <b-row>
            <b-col md="9" sm="7" class="my-1">
              <h3 class="card-title subListText">Subscription List</h3>
            </b-col>
            <b-col md="3" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>

            <b-col cols="12" v-if="filteredItems && filteredItems.length">
              <b-table
                hover
                class="table_subscription"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(PropertyId)="data">
                  <div style="display: flex; align-items: center" @click="goToPropertyDetailPage(data.item)">
                    <a
                      style="text-decoration: underline;"
                    >
                      {{ data.item.propertyId }}
                    </a>
                  </div>
                </template>

                <template #cell(Property)="data">
                  <div @click="callPropertyEmit(data.item)">
                    <router-link target="_blank" :to="`listing-management/${data.item.id}`">
                    <div style="display: flex; align-items: center">
                      <img
                        v-if="data.item.photos && data.item.photos.length"
                        width="40px"
                        height="40px"
                        style="
                          object-fit: cover;
                          border-radius: 5px;
                          margin-right: 15px;
                          min-width: 40px;
                          cursor:pointer;
                        "
                        :src="data.item.photos[0]"
                        alt="property"
                      />
                      <div>
                        <span
                          style="
                            white-space: normal;
                            display: block;
                            color: #17D1C6;
                            font-weight: 500;
                            cursor:pointer;
                          "
                          >{{ data.item.PropertyLocation }}</span
                        >
                        <!-- <span class="askingPriceText">$ {{ data.item.askingPrice }}</span> -->
                      </div>
                    </div>
                    </router-link>
                  </div>
                </template>

                <template #cell(Status)="data">
                  <div style="display: flex; align-items: center;text-transform:capitalize" v-if="data.item.isArchived == false" :class="data.item.Status">
                    <span class="status-area" >{{ data.item.Status }}</span>
                  </div>
                  <div style="display: flex; align-items: center;text-transform:capitalize" v-else>
                    <span class="status-area">Archived</span>
                  </div>
                </template>
                <template #cell(Action)="data">
                   <div  :class="data.item.Action" v-if="data.item.SubKey == 1" style="position:relative;">
                   
                      <!-- <span class="status-area">{{ data.item.Status }}</span> -->
                      <b-dropdown
                      :text="data.item.Action"
                      :class="data.item.Action"
                      :disabled="showSpinner"
                    >
                      <b-dropdown-item
                        style="text-transform: capitalize !important"
                        v-for="datas in displayStatus(
                          getSubStatus,
                          data.item.Action
                        )"
                        :key="datas.displayName"
                        @click="openModel(data.item,datas)"
                        class="boldactive"
                        
                      >
                        {{ datas.displayName }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <span style="white-space: nowrap;bottom: -22px;" class="auto-renew-span">Auto-renews On {{data.item.AutoRenewDate}}</span>
                  </div>
                  <div style="display: flex; align-items: center;text-transform:capitalize" :class="data.item.Action" v-else>
                    <span class="status-area">{{ data.item.Action }}</span>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>
            <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="filteredItems && filteredItems.length">
              <!-- page length -->
             <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of  {{ filteredItems.length }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filteredItems.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-changeStatus"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to update the status?</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { dbCollections } from "@/utils/firebaseCollections";
import {getCommaSeperatedNumber } from "@/utils/commonMethods";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import moment from "moment";
import axios from "axios";
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: true,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "PropertyId",
          label: "Property ID",
        },
        {
          key: "Property",
          label: "Property",
        },
        {
          key: "SubscriptionType",
          label: "Subscription Type",
        },
        {
          key: "ListedBy",
          label: "Listed By",
        },
        {
          key: "listing_date",
          label: "Listing Date",
        },
        {
          key: "SubscriptionAmount",
          label: "Subscription Amount",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Subscription Status",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      updatedStatus: {},
      subStatusObject : {}
    };
  },

  created() {
    var self = this;
    self.userData().then(() => {
      self.propertyData().then(() => {
        self.showSpinner = false;
      })
    });
  },

  methods: {
    ...mapActions({
      propertyData: "property/allPropertyData",
      userData: "user/userData",
    }),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToPropertyDetailPage(data){
      var self=this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-detail",
        params: { id: data.id },
      });
    },
    callPropertyEmit(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
    },
    displayStatus(array,status){
      let subStatusArray = [];
      if(array && array.length){
        array.forEach(data => {
          if(data.displayName != status){
            subStatusArray.push(data);
          }
        })
        return subStatusArray;
      }else{
        return [];
      }
    },
    openModel(data,datas) {
      this.updatedStatus = data;
      this.subStatusObject = datas;
      this.$bvModal.show(`modal-changeStatus`);
    },
    changeStatus(){
      // debugger; // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let index = self.filteredItems.findIndex(items => {
        return items.id == self.updatedStatus.id
      })
      if(index != -1){
        console.log(self.updatedStatus.subscription,'[self.updatedStatus]',self.getStatuses,'elf.subStatusObject.key',self.getStatuses.find((e)=>{return e.key == (self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3)}));
        // self.subStatusObject.key = self.subStatusObject.key === 1 ? 2 : self.subStatusObject.key === 2 ? 4 : self.subStatusObject.key === 3 ? 5 : self.subStatusObject.key === 4 ? 3 : 3 ;
        self.filteredItems[index].SubKey = self.subStatusObject.key;
        self.filteredItems[index].Action = self.subStatusObject.displayName;
        self.filteredItems[index].Status =self.getStatuses?.find((e)=>{return e.key == (self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3)}).displayName;
        if(self.updatedStatus.subscription && self.updatedStatus.subscription != ''){
          axios.post(process.env.VUE_APP_APIURL+'/api/v1/delete-subcription',{subcriptionId :self.updatedStatus.subscription,statusKey:self.subStatusObject.key })
          .then((res) => {
            if(res){
              let updateObject = {
                status:self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3,
                subscriptionStatus:self.subStatusObject.key
              }
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,self.updatedStatus.id,updateObject,(res) => {
                if(res){
                  self.$toast.success('Subscription status updated successfully');
                  self.showSpinner = false;
                }
              })
            }
          }).catch((error) => {
            console.log('ERROR in call subscription delete api',error);
            self.showSpinner = false;
          })
        }else{
          let updateObject = {
            status:self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3,
            subscriptionStatus:self.subStatusObject.key,
          }
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,self.updatedStatus.id,updateObject,(res) => {
            if(res){
              self.$toast.success('Subscription status updated successfully');
              self.showSpinner = false;
            }
          })
        }
      }

    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key];
      const obj2 = object2[key];
      if (obj1 > obj2) {
        return -1;
      }
      if (obj1 < obj2) {
        return 1;
      }
      return 0;
    },
  },

  computed: {
    ...mapGetters({
      getPropertyArray: "property/getAllProperty",
      getSubStatus: "admin/subScriptionStatus",
      allUserDataArray: "user/allUserData",
      getStatuses: "admin/statusGetter"
    }),
    filteredItems() {
      // debugger; // eslint-disable-line
      var self = this;
      let userName = "";
      self.showSpinner = true;
      let arrayOfPropertyData = [];
      if (self.getPropertyArray && self.getPropertyArray.length && self.getStatuses && self.getStatuses.length && self.getSubStatus && self.getSubStatus) {
        let tmp = self.getPropertyArray;
        tmp.forEach((data) => {
          if(data.subscriptionStatus == 1 || data.subscriptionStatus == 3){
            if (self.allUserDataArray.length > 0 && data.userId) {
              var index = self.allUserDataArray.findIndex((item) => {
                return item.id == data.userId;
              });
              if (index != -1) {
                userName = `${self.allUserDataArray[index].firstName} ${self.allUserDataArray[index].lastName}`;
              } else {
                userName = "N/A";
              }
            }else{
              userName = "N/A";
            }
            if (data.listingType == "free_or_public") {
              arrayOfPropertyData.push({
                ...data,
                country: data.contractBCountry,
                state: data.contractBState,
                askingPrice: getCommaSeperatedNumber(data.contractDAskingPrice),
                photos: data.contractFUploadPhotos,
                listing_date: data.createdAt
                  ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
                  : "",
                Action:data.subscriptionStatus ? 
                  self.getSubStatus[data.subscriptionStatus - 1].displayName : 'N/A',
                  SubKey:data.subscriptionStatus,
                ListedBy: userName,
                SubscriptionType:'Free Or Public',
                SubscriptionAmount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                Status:data.status ? self.getStatuses[data.status - 1].displayName : '',
                Subscription:data.subscription ? data.subscription : '',
                AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                subscriptionDate:data.subscriptionDate ? data.subscriptionDate : '',
                PropertyLocation: `${
                        data.contractBStreetNumber
                          ? data.contractBStreetNumber
                          : ""
                      } ${
                        data.contractBStreetName
                          ? data.contractBStreetName + ","
                          : ""
                      } ${
                        data.contractBUnitorLot
                          ? data.contractBUnitorLot + ", "
                          : ""
                      }${data.contractBCity}, ${
                        data.contractBState
                      }, ${data.contractBCountry}, ${
                        data.contractBZipCode
                      }`,
              });
            } else if (data.listingType == "mental_or_detox") {
              arrayOfPropertyData.push({
                ...data,
                country: data.ownerBCountry,
                state: data.ownerBState,
                askingPrice: getCommaSeperatedNumber(data.ownerDAskingPrice),
                photos: data.ownerFUploadPhotos,
                listing_date: data.createdAt
                  ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
                  : "",
                Action:data.subscriptionStatus ? 
                  self.getSubStatus[data.subscriptionStatus - 1].displayName: 'N/A',
                SubKey:data.subscriptionStatus,
                ListedBy: userName,
                SubscriptionType:'Mental Or Detox',
                SubscriptionAmount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                Status:data.status ? self.getStatuses[data.status - 1].displayName : '',
                Subscription:data.subscription ? data.subscription : '',
                AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                subscriptionDate:data.subscriptionDate ? data.subscriptionDate : '',
                PropertyLocation: `${
                        data.ownerBStreetNumber
                          ? data.ownerBStreetNumber
                          : ""
                      } ${
                        data.ownerBStreetName
                          ? data.ownerBStreetName + ","
                          : ""
                      } ${
                        data.ownerBUnitorLot
                          ? data.ownerBUnitorLot + ", "
                          : "" 
                      }${data.ownerBCity}, ${
                        data.ownerBState
                      }, ${data.ownerBCountry}, ${
                        data.ownerBZipCode
                      }`, 
              });
            } else if (data.listingType == "half_way_or_shared" || data.listingType == 'mental_or_detox_facility' || data.listingType == 'free_or_public_shelter') {
              arrayOfPropertyData.push({
                ...data,
                country: data.leaseBCountry,
                state: data.leaseBState,
                askingPrice:getCommaSeperatedNumber(data.leaseDAskingPrice),
                photos: data.leaseFUploadPhotos,
                listing_date: data.createdAt
                  ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
                  : "",
                Action:data.subscriptionStatus ? 
                  self.getSubStatus[data.subscriptionStatus - 1].displayName: 'N/A',
                SubKey:data.subscriptionStatus,
                ListedBy: userName,
                SubscriptionType:data.listingType === 'half_way_or_shared'
                                ? 'Half-Way or Shared House'
                                : data.listingType === 'mental_or_detox_facility'
                                ? 'Mental or Detox Facility'
                                : data.listingType === 'free_or_public_shelter'
                                ? 'Free or Public Shelter'
                                : '' ,
                SubscriptionAmount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                Status:data.status ? self.getStatuses[data.status - 1].displayName : '',
                AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                subscriptionDate:data.subscriptionDate ? data.subscriptionDate : '',
                PropertyLocation: `${
                    data.leaseAStreetNumber
                      ? data.leaseAStreetNumber
                      : ""
                  } ${
                    data.leaseAStreetName
                      ? data.leaseAStreetName + ","
                      : ""
                  } ${
                    data.leaseAUnitorLot
                      ? data.leaseAUnitorLot + ","
                      : ""
                  } ${data.leaseBCity}, ${
                    data.leaseBState
                  }, ${data.leaseBCountry}, ${
                    data.leaseBZipCode
                  }`,
                  isArchived: data.isArchived ? data.isArchived : false
              });
            }
          }
        });
        // console.log("arrayOfPropertyData", arrayOfPropertyData);
        self.showSpinner = false;
        arrayOfPropertyData.sort((book1, book2) => {
          return self.compareObjects(book1, book2, "subscriptionDate");
        });
        return arrayOfPropertyData;
      }else{
        self.showSpinner =false;
      }
    },
  },
};
</script>

<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.btn {
  text-transform: capitalize !important;
}
</style>